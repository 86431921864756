import React from 'react'

export default function AmharicBiblePrivacyPolicy() {
  return (
    <div>

      <div class="bg-gray-100">
        <div class="container mx-auto px-4 py-8">
          <h1 class="text-3xl font-bold mb-4">Privacy Policy for Amharic Bible</h1>

          <p class="mb-4">
            <b>Amharic Bible</b> is developed by <b>ZOE BIBLE (Fyn Systems)</b> as an ad-supported, free app. This Privacy Policy outlines how the app collects, uses, and protects user data, including compliance with Google Play’s policies.
          </p>

          <h2 class="text-2xl font-bold mb-2">Information Collection and Use</h2>

          <p class="mb-4">
            We may collect the following information:
          </p>

          <ul class="list-disc list-inside mb-4">
            <li><b>No Personal Data Collection</b></li>
            <p class="mx-4">We do not collect or transfer any personally identifiable information (PII) from users.</p>
            <li><b>Third-Party Services</b></li>
            <p class="mx-4">
              The app uses third-party services such as:
              <p class='mx-8'>
                <b>• Google Analytics: </b> To gather anonymous data on app usage to improve user experience.
                <br />
                <b>• AdMob: </b> To display personalized advertisements based on user activity.
              </p>
            </p>
            <p class="mx-4">These third-party services may collect certain data for their functionality and analytics. You can refer to their privacy policies for more details
              <p class="mx-8">
                •	Google Analytics Privacy Policy
                <br />
                •	AdMob Privacy Policy
              </p>
            </p>
            <li><b>Crash Logs and Error Reports</b></li>
            <p class="mx-4">
              In case of app crashes or errors, we may request your consent to collect crash data. This data may include device-specific information, such as:
              •	Device name
              •	Operating system version
              •	App configuration details at the time of the crash
              •	Time and date of usage
            </p>
            <li>
              This data is solely used to diagnose and resolve technical issues and improve app stability.
            </li>

            <li><b>App Pressions</b></li>
            <p class="mx-4">
              The app may request the following permissions:
              <p class="mx-8">
                <b> •	External Storage Access  </b> To store or retrieve Bible data for smoother usage.
                <br />
                <b> •	BOOT_COMPLETE  </b>
                To ensure proper functionality when the device is restarted.
              </p>
            </p>
            <li>
              These permissions are used strictly to enhance user experience and are not used for data collection purposes.
            </li>
          </ul>

          <h2 class="text-2xl font-bold mb-2">Children's Privacy</h2>

          <p class="mx-4">
            Our services are not intended for children under the age of 13. We do not knowingly collect any personal information from children. If you believe that we have mistakenly collected such information, please contact us so we can promptly delete it.
          </p>

          <h2 class="text-2xl font-bold mb-2">Data Security</h2>

          <p class="mx-4 mb-4">
            We are committed to ensuring that your information is secure. In order to prevent unauthorized access or
            disclosure,
            we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the
            information we collect online.
          </p>

          <h2 class="text-2xl font-bold mb-2">Copyright Information</h2>

          <p class="mb-4 mx-4">
            The app uses the Digital Edition of the Revised Amharic Bible (2006), with permission from the Bible Society of Ethiopia and Dirk Rockmann. Any non-commercial use of this data must fully include this copyright statement. Commercial use requires written permission from the copyright holders.
          </p>

          <h2 class="text-2xl font-bold mb-2">Changes to This Privacy Policy</h2>

          <p class="mb-4 mx-4">
            We may update our Privacy Policy from time to time. Any changes will be posted on this page, and the new policy will take effect immediately upon posting.

          </p>

          <h2 class="text-2xl font-bold mb-2">Contact Us</h2>

          <p class="mb-4 mx-4">
            If you have any questions or suggestions regarding this Privacy Policy, please feel free to contact us at:
            <br />

            <b class='mx-4'>fynnsystemss@gmail.com</b>
          </p>

          <p class="mb-4">
            This privacy policy is subject to change without notice.
          </p>
        </div>
      </div>
    </div>
  )
}
